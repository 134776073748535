
import { defineComponent, PropType } from 'vue'
import { TravelAccountJourneyPassenger } from '@/models/TravelAccountJourney'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'
import { fareStrings } from '@/models/FareClass'

export default defineComponent({
  name: 'BookingPassengers',
  components: {
    PeopleIcon
  },
  props: {
    passengers: {
      type: Object as PropType<TravelAccountJourneyPassenger[]>,
      required: true
    }
  },
  setup (props) {
    return { props, fareStrings }
  }
})
