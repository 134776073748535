
import { TravelAccountJourneyDetailed } from '@/models/TravelAccountJourney'
import { computed, defineComponent, PropType } from 'vue'
import LegsPricing from '@/components/BookingInfo/LegsPricing.vue'
import BookingPassengers from '@/components/BookingInfo/BookingPassengers.vue'
import LegItineraryList from '@/components/BookingInfo/LegItineraryList.vue'
import { formatTime, formatDate, calculateTimeDiff } from '@/helpers'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'

export default defineComponent({
  name: 'LegInfo',
  components: {
    LegsPricing,
    BookingPassengers,
    LegItineraryList,
    PeopleIcon
  },
  props: {
    journey: {
      type: Object as PropType<TravelAccountJourneyDetailed>,
      required: true
    },
    showJourneyHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const lastLeg = computed(() =>
      props.journey.Legs.reduce((a, b) =>
        a.OrderNumber > b.OrderNumber ? a : b))
    const firstLeg = computed(() =>
      props.journey.Legs.reduce((a, b) =>
        a.OrderNumber < b.OrderNumber ? a : b))
    const totalTripTime = computed(() =>
      calculateTimeDiff(firstLeg.value.DepartureTime, lastLeg.value.ArrivalTime))
    return { firstLeg, lastLeg, formatTime, totalTripTime, formatDate }
  }
})
