
import { computed, defineComponent, PropType } from 'vue'
import { TravelAccountJourneyLeg } from '@/models/TravelAccountJourney'
import { formatPrice } from '@/helpers'
import { fareStrings } from '@/models/FareClass'

export default defineComponent({
  name: 'LegsPricing',
  props: {
    legs: {
      type: Object as PropType<TravelAccountJourneyLeg[]>,
      required: true
    }
  },
  setup (props) {
    const subtotal = computed(() => {
      const passengers = props.legs.reduce(
        (p, c) => p + c.PassengerPricing.reduce((prev, cur) =>
          prev + cur.Price, 0),
        0
      )
      const extras = props.legs.reduce(
        (p, c) => p + c.Extras.reduce((prev, cur) =>
          prev + cur.Price, 0),
        0
      )

      return passengers + extras
    })
    return { props, formatPrice, fareStrings, subtotal }
  }
})
