import { Fares, FareMap } from '@/models/FareClass'
import { BusStop } from '@/models/BusStop'
import dayjs from 'dayjs'
import router from '@/router'
import { TravelAccountJourneyDetailed } from '@/models/TravelAccountJourney'
import { getBookingInfo } from '@/expressway-api/travelAccountJourneys.api'

// eslint-disable-next-line complexity
export default async function bookAgain (
  shoppingBasketId: string,
  journeys?: TravelAccountJourneyDetailed[]
) {
  if (!journeys) {
    journeys = await getBookingInfo(shoppingBasketId)
  }
  if (!journeys || journeys.length === 0) {
    throw new Error('Error generating search')
  }

  const hasOutbound = journeys.find((x: TravelAccountJourneyDetailed) => x.IsOutbound)
  const hasInbound = journeys.find((x: TravelAccountJourneyDetailed) => !x.IsOutbound)

  const isOneWay = !(hasInbound && hasOutbound)

  const passengers = {
    adult: 0,
    child: 0,
    youngAdult: 0,
    student: 0,
    dsp: 0,
    seatReservation: 0
  } as Fares

  Object.keys(passengers)
    .forEach(key => {
      if (!journeys || journeys.length === 0) return

      passengers[key] = journeys[0].Passengers.filter(passenger =>
        passenger.PassengerCategory === FareMap[key]
      ).length
    })

  const outwards = (journeys?.find(b => b.IsOutbound) ?? []) as TravelAccountJourneyDetailed

  const origin = {
    BusStopId: outwards.DepartureStopId,
    BusStopName: outwards.DepartureStopName,
    CityId: outwards.DepartureCityId,
    CityName: outwards.DepartureCityName
  } as BusStop

  const destination = {
    BusStopId: outwards.DestinationStopId,
    BusStopName: outwards.DestinationStopName,
    CityId: outwards.DestinationCityId,
    CityName: outwards.DestinationCityName
  } as BusStop

  router.push(
    {
      name: 'Journey Search',
      params: {
        props: JSON.stringify({
          oneWay: isOneWay,
          destination,
          origin,
          departureDate: dayjs().format('YYYY-MM-DD'),
          returnDate: dayjs().format('YYYY-MM-DD'),
          passengers
        })
      }
    })
}
