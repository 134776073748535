
import { defineComponent, PropType } from 'vue'
import { TravelAccountJourneyLeg } from '@/models/TravelAccountJourney'
import { formatTime, calculateTimeDiff } from '@/helpers'

export default defineComponent({
  name: 'LegItinerary',
  props: {
    leg: {
      type: Object as PropType<TravelAccountJourneyLeg>,
      required: true
    }
  },
  setup () {
    const durationCalculator = (x: string, y: string) => calculateTimeDiff(x, y)
    return { formatTime, durationCalculator }
  }
})
