
import { computed, defineComponent, PropType, ref } from 'vue'
import {
  TravelAccountJourneyDetailed,
  TravelAccountJourneyLeg
} from '@/models/TravelAccountJourney'
import Modal from '@/components/Modal.vue'
import TicketDownloadButton from '@/components/TicketDownloadButton.vue'
import { formatDate } from '@/helpers'
import ArrowForward from '@/components/vectors/ArrowForward.vue'
import Spinner from '@/components/Spinner.vue'
import TicketsDisplayList from '@/components/BookingInfo/TicketsDisplayList.vue'
import GenericError from '@/components/GenericError.vue'

export default defineComponent({
  name: 'TicketsModal',
  emits: ['update:modelValue'],
  components: {
    Modal,
    ArrowForward,
    Spinner,
    TicketDownloadButton,
    TicketsDisplayList,
    GenericError
  },
  props: {
    journeys: {
      type: Array as PropType<TravelAccountJourneyDetailed[]>,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const showPassengerTicketsModal = ref(false)
    const activePassengerTickets = ref()
    const activePassengerTicketsDirection = ref('')
    const activeLegForTickets = ref({} as TravelAccountJourneyLeg)
    const closeModal = () => {
      emit('update:modelValue', false)
    }

    const showPassengerTickets = (
      journey: TravelAccountJourneyDetailed,
      leg: TravelAccountJourneyLeg
    ) => {
      activeLegForTickets.value = leg
      activePassengerTickets.value = journey.Passengers.map(p => ({
        PassengerCategory: p.PassengerCategory,
        PassengerName: p.PassengerName,
        TicketNumber: p.ProductCode, // TODO - not sure about the value
        Ticket: p.Tickets.find(t => t.LegOrderNumber === leg.OrderNumber)
      }))
      activePassengerTicketsDirection.value = journey.IsOutbound ? 'outward' : 'return'
      showPassengerTicketsModal.value = true
    }

    return {
      showPassengerTicketsModal,
      showPassengerTickets,
      activeLegForTickets,
      activePassengerTickets,
      activePassengerTicketsDirection,
      closeModal,
      formatDate,
      basketId: computed(() => props.journeys[0]?.ShoppingBasketId)
    }
  }
})
