
import { computed, defineComponent, PropType, ref, onBeforeUnmount, onMounted } from 'vue'
import Modal from '@/components/Modal.vue'
import TicketDisplay from '@/components/BookingInfo/TicketDisplay.vue'
import { TravelAccountJourneyLeg, PassengerTicket } from '@/models/TravelAccountJourney'
import ArrowBackward from '@/components/vectors/ArrowBackward.vue'
import ArrowForward from '@/components/vectors/ArrowForward.vue'

export default defineComponent({
  emits: ['close'],
  components: {
    Modal,
    TicketDisplay,
    ArrowBackward,
    ArrowForward
  },
  props: {
    passengerTickets: {
      type: Array as PropType<PassengerTicket[]>,
      required: true
    },
    leg: {
      type: Object as PropType<TravelAccountJourneyLeg>,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const activeTicketIndex = ref(0)
    const activePassenger = computed(() => props.passengerTickets[activeTicketIndex.value])
    const goToNext = () => {
      if (props.passengerTickets.length > activeTicketIndex.value + 1) {
        activeTicketIndex.value++
      }
    }
    const goToPrevious = () => {
      if (activeTicketIndex.value > 0) {
        activeTicketIndex.value--
      }
    }
    const touchstartX = ref(0)
    const touchendX = ref(0)

    const handleGesture = () => {
      if (Math.abs(touchendX.value - touchstartX.value) < 60) return

      if (touchendX.value < touchstartX.value) goToNext()
      if (touchendX.value > touchstartX.value) goToPrevious()
    }
    const touchstart = (e: TouchEvent) => {
      touchstartX.value = e.changedTouches[0].screenX
    }
    const touchend = (e: TouchEvent) => {
      touchendX.value = e.changedTouches[0].screenX
      handleGesture()
    }
    onMounted(() => {
      document.addEventListener('touchstart', touchstart)
      document.addEventListener('touchend', touchend)
    })
    onBeforeUnmount(() => {
      document.removeEventListener('touchstart', touchstart)
      document.removeEventListener('touchstart', touchend)
    })
    return {
      goToNext,
      goToPrevious,
      activePassenger,
      activeTicketIndex
    }
  }
})
