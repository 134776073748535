
import QrcodeVue from 'qrcode.vue'
import { defineComponent, PropType } from 'vue'
import { TravelAccountJourneyLeg, PassengerTicket } from '@/models/TravelAccountJourney'
import LegItinerary from '@/components/BookingInfo/LegItinerary.vue'
import { formatTime, formatDate, calculateTimeDiff } from '@/helpers'
import { fareStrings } from '@/models/FareClass'

export default defineComponent({
  name: 'TicketDisplay',
  components: {
    LegItinerary,
    QrcodeVue
  },
  props: {
    passengerTicket: {
      type: Object as PropType<PassengerTicket>,
      required: true
    },
    leg: {
      type: Object as PropType<TravelAccountJourneyLeg>,
      required: true
    },
    direction: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup () {
    const durationCalculator = (x: string, y: string) => calculateTimeDiff(x, y)
    return {
      formatTime,
      durationCalculator,
      fareStrings,
      formatDate
    }
  }
})
