
import { defineComponent, PropType } from 'vue'
import { TravelAccountJourneyLeg } from '@/models/TravelAccountJourney'
import { formatTime, calculateTimeDiff } from '@/helpers'
import LegItinerary from '@/components/BookingInfo/LegItinerary.vue'
export default defineComponent({
  name: 'LegItineraryList',
  components: {
    LegItinerary
  },
  props: {
    legs: {
      type: Object as PropType<TravelAccountJourneyLeg[]>,
      required: true
    }
  },
  setup () {
    const durationCalculator = (x: string, y: string) => calculateTimeDiff(x, y)
    return { formatTime, durationCalculator }
  }
})
