<template>
<svg
  width="16"
  height="17"
  viewBox="0 0 16 17"
  fill="#262626"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16 7.88281H3.83L9.42 2.29281L8 0.882812L0 8.88281L8 16.8828L9.41 15.4728L3.83 9.88281H16V7.88281Z"
    fill-rule="evenodd"
  />
</svg>
</template>
