
import { defineComponent, PropType, computed, ref } from 'vue'
import { TravelAccountJourneyDetailed } from '@/models/TravelAccountJourney'
import TicketsModal from '@/components/BookingInfo/TicketsModal.vue'
import TicketDownloadButton from '@/components/TicketDownloadButton.vue'
import useBookAgain from '@/composables/useBookAgain'
import Spinner from '@/components/Spinner.vue'
import useSnackbar from '@/composables/useSnackbar'

export default defineComponent({
  name: 'BookingActions',
  components: {
    TicketsModal,
    TicketDownloadButton,
    Spinner
  },
  props: {
    basketId: {
      type: String,
      required: true
    },
    journeys: {
      type: Array as PropType<TravelAccountJourneyDetailed[]>,
      required: true
    }
  },
  setup (props) {
    const { setSnackbar } = useSnackbar()
    const loadingBookAgain = ref(false)
    const outwards = computed(() => props.journeys ? props.journeys.filter(b => b.IsOutbound) : [])
    const returns = computed(() => props.journeys ? props.journeys.filter(b => !b.IsOutbound) : [])
    const showTickets = ref(false)
    const bookAgain = () => {
      loadingBookAgain.value = true
      useBookAgain(props.journeys[0].ShoppingBasketId, props.journeys)
        .catch(e => {
          setSnackbar(e.message)
          loadingBookAgain.value = false
        })
    }
    return {
      outwards,
      returns,
      showTickets,
      bookAgain,
      loadingBookAgain
    }
  }
})
