
import { defineComponent, ref, computed } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { TabOption } from '@/models/System'
import { useRoute } from 'vue-router'
import { getBookingInfo } from '@/expressway-api/travelAccountJourneys.api'
import { formatDate } from '@/helpers'
import useSwrv from 'swrv'
import ArrowReturn from '@/components/vectors/ArrowReturn.vue'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import Actions from '@/components/BookingInfo/Actions.vue'
import LegInfo from '@/components/BookingInfo/LegInfo.vue'
import Tabs from '@/components/Tabs.vue'

export default defineComponent({
  name: 'MyBookings',
  components: {
    PageHeader,
    Spinner,
    GenericError,
    PeopleIcon,
    ArrowReturn,
    Actions,
    LegInfo,
    Tabs
  },
  setup () {
    const route = useRoute()
    const basketId = route.params.id as string
    const tabSelected = ref('outward')
    const { data: bookingInfo, error } = useSwrv(
      () => `/baskets/${basketId}/booked-journey-data`,
      () => getBookingInfo(basketId)
    )
    const outwards = computed(() => bookingInfo.value?.filter(b => b.IsOutbound) ?? [])
    const returns = computed(() => bookingInfo.value?.filter(b => !b.IsOutbound) ?? [])
    const destination = computed(() => {
      if (outwards.value.length === 0) return ''

      const [{ DepartureCityName, DestinationCityName }] = outwards.value
      return `${DepartureCityName} - ${DestinationCityName}`
    })

    const HeadDates = computed(() => {
      if (outwards.value.length === 0) return ''

      const [{ DepartureTime: outwardTime }] = outwards.value
      if (returns.value.length === 0) return `${formatDate(outwardTime)}`

      const [{ DepartureTime: returnTime }] = returns.value
      return `${formatDate(outwardTime)} - ${formatDate(returnTime)}`
    })

    const passengers = computed(() =>
      outwards.value.length > 0 ? outwards.value[0].PassengersNumber : ''
    )
    const updateTabSelected = (newTab: string) => { tabSelected.value = newTab }
    const activeJourneys = computed(() =>
      tabSelected.value === 'outward' ? outwards.value : returns.value
    )
    const loading = computed(() => !(bookingInfo.value || error.value))
    const tabsOptions = computed(() => {
      const tabs: TabOption[] = [
        {
          value: 'outward',
          label: 'Outward'
        }
      ]
      if (bookingInfo.value?.find(x => !x.IsOutbound)) {
        tabs.push({ value: 'return', label: 'Return' })
      }
      return tabs
    })
    const isEdited = computed(() => outwards.value.length > 1 || returns.value.length > 1)

    return {
      tabsOptions,
      bookingInfo,
      passengers,
      loading,
      destination,
      error,
      HeadDates,
      updateTabSelected,
      activeJourneys,
      isEdited,
      basketId
    }
  }
})
